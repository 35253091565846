"use client";
import { AppBar, Stack, Typography, IconButton, Toolbar } from "@mui/material";
import {
  MenuRounded as MenuIcon,
  MenuOpenRounded as MenuOpenIcon
} from "@mui/icons-material";

import { useStore, observer } from "../../service/mobx";
import { useWhiteLabelling } from "../Hooks";
import { NextLink } from "../Link";

function TopBar() {
  const { layout, device } = useStore();
  const isWhiteLabelled = useWhiteLabelling();

  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={theme => ({
        height: 72,
        width: "100vw",
        bgcolor: "var(--surface-dim)",
        display: isWhiteLabelled ? "none" : undefined,
        ...(layout.navOpen ? {} : theme.fadeEdge("bottom", 70))
      })}
    >
      <Toolbar disableGutters>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            width: "100%",
            pb: { compact: 0, expanded: 1 },
            pt: { compact: 0, expanded: 1.5 },
            pl: { compact: 0, expanded: 1 },
            pr: { compact: 0, expanded: 3 }
          }}
        >
          <Stack spacing={2} direction="row" alignItems="center">
            <IconButton
              onClick={() => layout.set.navOpen(!layout.navOpen)}
              sx={{
                height: 56,
                width: 56,
                borderRadius: "var(--shape-md-round)"
              }}
            >
              {layout.navOpen === false || device.isPhone ? (
                <MenuIcon />
              ) : (
                <MenuOpenIcon />
              )}
            </IconButton>
            <Typography
              fontSize={24}
              fontWeight={600}
              typography="brand"
              color="var(--secondary-color)"
              href="/"
              component={NextLink}
              sx={{
                textDecoration: "none",
                "&:hover": {
                  color: "var(--primary-color)"
                }
              }}
            >
              bytez
            </Typography>
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

export default observer(TopBar);
