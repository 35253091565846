"use client";
import { useEffect, useState } from "react";
import dynamic from "next/dynamic";

import { useStore, observer } from "../../service/mobx";

const SwipeableBottomSheet = dynamic(
  () => import("react-swipeable-bottom-sheet"),
  { ssr: false }
);
const CloseIcon = dynamic(() => import("@mui/icons-material/Close"));
const Box = dynamic(() => import("@mui/material/Box"));
const Fade = dynamic(() => import("@mui/material/Fade"));
const IconButton = dynamic(() => import("@mui/material/IconButton"));
const Stack = dynamic(() => import("@mui/material/Stack"));

function BottomSheet() {
  const [mount, setMount] = useState(false);
  const { bottomSheet, device } = useStore();
  const { Component } = bottomSheet;

  useEffect(() => {
    if (bottomSheet.open) {
      setMount(true);
      bottomSheet.set.expanded(true);

      return () => {
        setMount(false);
        bottomSheet.set.expanded();
      };
    }
  }, [bottomSheet, bottomSheet.open, Component]);

  useEffect(() => {
    if (bottomSheet.expanded) {
      document.body.style.overflow = "hidden";

      return () => document.body.style.removeProperty("overflow");
    }
  }, [bottomSheet.expanded]);

  useEffect(() => {
    if (device.isPhone) {
      return () => {
        setMount(false);
        bottomSheet.onClose();
      };
    }
  }, [bottomSheet, device.isPhone]);

  // console.log(
  //   {
  //     mount,
  //     ...bottomSheet
  //   },
  //   {
  //     isPhone: device.isPhone,
  //     measured: device.measured,
  //     isNotPhone: device.isNotPhone
  //   }
  // );

  return device.measured === false || device.isNotPhone ? null : (
    <Fade
      in={mount}
      timeout={{ enter: 1, exit: 300 }}
      onExited={bottomSheet.onClose}
    >
      <Box
        sx={{
          "& *::-webkit-scrollbar": {
            display: "none"
          }
        }}
      >
        <SwipeableBottomSheet
          topShadow={false}
          scrollTopAtClose={false}
          overflowHeight={bottomSheet.peek ? 64 : 0}
          // swipeableViewsProps={{
          //   enableMouseEvents: true,
          //   disableLazyLoading: true,
          //   // hysteresis: 1,
          //   threshold: Infinity
          // }}
          onChange={expanded => {
            bottomSheet.set.expanded(expanded);

            if (bottomSheet.peek === false) {
              setTimeout(setMount, 300, false);
            }
          }}
          open={bottomSheet.expanded}
          style={{ zIndex: "var(--zIndex-modal)" }}
          overlayStyle={{
            opacity: bottomSheet.expanded ? 0.32 : 0,
            backgroundColor: "var(--surface-on-variant)"
          }}
          bodyStyle={{
            boxShadow: "var(--elevation-5)",
            borderRadius: "var(--shape-xl-top)",
            backgroundColor: bottomSheet.expanded
              ? "var(--surface-container-lowest)"
              : "var(--surface-container-highest)",
            border:
              bottomSheet.peek && bottomSheet.expanded === false
                ? "1px solid var(--outline-variant)"
                : undefined,
            transition: "background-color 200ms var(--motion-easing-emphasized)"
          }}
        >
          <Box
            sx={{
              px: 2,
              py: 2,
              // maxHeight: "95vh",
              overflowY: "auto",
              m: { compact: 0, large: 56 },
              top: "calc( 100vh - 56px )",
              borderRadius: "var(--shape-xl-top)",
              ...bottomSheet.sx
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              px={2}
              onClick={() => bottomSheet.set.expanded(true)}
            >
              <Box width={34} height={34} />
              <Box
                width={32}
                height={4}
                borderRadius="var(--shape-round)"
                bgcolor="var(--surface-on-variant)"
              />
              <Fade in={bottomSheet.peek && bottomSheet.expanded === false}>
                <IconButton
                  size="small"
                  href="/agent"
                  scroll={false}
                  onClick={event => {
                    setMount(false);
                    event.stopPropagation();
                  }}
                >
                  <CloseIcon
                    sx={{
                      width: 16,
                      height: 16,
                      color: "var(--secondary-color)"
                    }}
                  />
                </IconButton>
              </Fade>
            </Stack>
            {Component ? <Component /> : null}
          </Box>
        </SwipeableBottomSheet>
      </Box>
    </Fade>
  );
}

export default observer(BottomSheet);
