"use client";
import { useEffect } from "react";
import dynamic from "next/dynamic";
import { usePathname } from "next/navigation";

import { useStore, observer } from "../../service/mobx";

const Menu = dynamic(() => import("@mui/material/Menu"));

function MenuComponent() {
  const { bottomSheet, device, menu, user } = useStore();
  const pathname = usePathname();

  useEffect(() => {
    if (menu.open && device.isPhone) {
      bottomSheet.set.sx({ px: 0, pb: 0, overflowY: "hidden" });
      bottomSheet.configure(menu.Component);
      bottomSheet.set.onClose(() => {
        menu.onClose();
        bottomSheet.reset();
      });
    }
  }, [bottomSheet, menu, menu.open, device.isPhone]);

  // when changing pages, reset menu
  useEffect(() => {
    menu.set.open(false);
  }, [menu, pathname, device.isPhone, user.loaded]);

  return device.measured === false || device.isPhone ? null : (
    <Menu
      anchorEl={
        menu.anchorId ? document.getElementById(menu.anchorId) : menu.anchor
      }
      open={menu.open}
      onClose={() => menu.set.open(false)}
      slotProps={{ paper: { sx: { ...paperSx, ...menu.sx.paper } } }}
      MenuListProps={{
        sx: { minWidth: 112, maxWidth: 280, ...menu.sx.menuList }
      }}
      TransitionProps={{ onExited: menu.onClose }}
      {...menu.options}
    >
      <menu.Component state={menu.state} />
    </Menu>
  );
}

export default observer(MenuComponent);

export const paperSx = {
  maxHeight: "50vh",
  bgcolor: "var(--surface-container-color)",
  boxShadow: "var(--elevation-2)",
  borderRadius: "var(--shape-xs-round)"
};
