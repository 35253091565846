import { forwardRef } from "react";
import Link from "next/link";

export const NextLink = forwardRef(function NextLink(props, ref) {
  return <Link prefetch ref={ref} scroll {...props} />;
});

export const ShallowLink = ({ onClick, href, ...props }) => (
  <Link
    prefetch
    replace
    scroll={false}
    href={href}
    {...props}
    onClick={event => {
      event.preventDefault();
      window.history.replaceState(null, "", href);
      onClick?.();
    }}
  />
);

export const LinkNoScroll = props => (
  <Link prefetch scroll={false} {...props} />
);
