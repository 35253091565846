"use client";
import { Snackbar, Slide, Button } from "@mui/material";

import { useStore, observer } from "../../service/mobx";

function SnackbarComponent() {
  const { device, bottomSheet, snackbar } = useStore();

  return (
    <Snackbar
      open={snackbar.open}
      onClose={() => snackbar.set.open(false)}
      slotProps={{
        transition: { onExited: () => snackbar.set.message() },
        content: {
          sx: theme => ({
            px: 2,
            height: snackbar.message?.line3
              ? 96
              : snackbar.message?.line2
              ? 68
              : 48,
            color: "var(--surface-inverse-on)",
            ...theme.typography.labelLg,
            borderRadius: "var(--shape-xs-round)",
            bgcolor: "var(--surface-inverse-color)",
            "& .MuiSnackbarContent-message": {
              p: 0
            },
            ...theme.motion.transition[snackbar.open ? "enter" : "exit"].perm
          })
        }
      }}
      slots={{ transition: Slide }}
      autoHideDuration={snackbar.message?.duration ?? 6e3}
      message={
        snackbar.message?.Component ||
        (snackbar.message?.line2 || snackbar.message?.line3 ? (
          <>
            <div>{snackbar.message?.line1}</div>
            <div>{snackbar.message?.line2}</div>
            <div>{snackbar.message?.line3}</div>
          </>
        ) : (
          snackbar.message?.text
        ))
      }
      action={snackbar.message?.actions?.map(({ label, ...props }) => (
        <Button
          key={label}
          {...props}
          sx={{
            color: "var(--primary-inverse)",
            borderRadius: "var(--shape-round)",
            "&:hover": {
              bgcolor: "var(--primary-container)",
              color: "var(--primary-on-container)"
            }
          }}
        >
          {label}
        </Button>
      ))}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: device.isPhone ? "center" : "right"
      }}
      sx={
        bottomSheet.open
          ? {
              bottom: {
                compact: bottomSheet.expanded ? 24 : 215 + 24,
                expanded: 24
              }
            }
          : { left: "unset" }
      }
    />
  );
}

export default observer(SnackbarComponent);
